<template>
    <AuthLayout>
        <div class="text-center space-y-4">
            <h1 class="text-heading-2">{{ title }}</h1>
            <div>{{ description }}</div>
            <FormButton @click="back()">Gå tilbake</FormButton>
        </div>
    </AuthLayout>
</template>

<script setup lang="ts">
const props = defineProps({
    code: Number,
    message: String
})

const back = () => {
    window.history.back();
}

const title = computed(() => {
    return {
        503: '503: Service Unavailable',
        500: '500: Server Error',
        404: '404: Page Not Found',
        403: '403: Forbidden',
        401: '401: Unauthorized',
    }[props.code]
})

const description = computed(() => {
    return props.message ?? {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
        401: 'Du har ikke tilgang',
    }[props.code]
})
</script>
